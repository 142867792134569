const CLIENT_ID = process.env.NEXT_PUBLIC_USERS_CLIENT_ID as string;
const CONFIG_CAT_KEY = process.env.NEXT_PUBLIC_CONFIG_CAT_KEY as string;
const CONFIG_CAT_CACHE_TIME = process.env
  .NEXT_PUBLIC_CONFIG_CAT_CACHE_TIME as string;
const WEBAPP_URL = process.env.NEXT_PUBLIC_WEBAPP as string;
const INTEGROMAT = process.env.NEXT_PUBLIC_INTEGROMAT_GET_CRM_SCHOOL as string;
const RUDDERSTACK_APIKEY = process.env.NEXT_PUBLIC_RUDDERSTACK_APIKEY as string;
const RUDDERSTACK_URL = process.env.NEXT_PUBLIC_RUDDERSTACK_URL as string;
const MANAGER_VERSION = process.env.NEXT_PUBLIC_VERSION as string;
const ITERABLE_APIKEY = process.env.NEXT_PUBLIC_ITERABLE_APIKEY as string;
const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT as string;
const INLINE_MANUAL = process.env.NEXT_PUBLIC_INLINE_MANUAL as string;
const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as string;
const GOOGLE_SECRET = process.env.NEXT_PUBLIC_GOOGLE_SECRET as string;
const MSAL_CLIENT_ID = process.env.NEXT_PUBLIC_MSAL_CLIENT_ID as string;
const COOKIE_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_DOMAIN as string;
const WEBCONTENTS_URL = process.env.NEXT_PUBLIC_WEBCONTENTS_URL as string;
const CORPORATE_WEBSITE = process.env.NEXT_PUBLIC_CORPORATE_WEBSITE as string;

export {
  CLIENT_ID,
  CONFIG_CAT_CACHE_TIME,
  CONFIG_CAT_KEY,
  COOKIE_DOMAIN,
  CORPORATE_WEBSITE,
  ENVIRONMENT,
  GOOGLE_CLIENT_ID,
  GOOGLE_SECRET,
  INLINE_MANUAL,
  INTEGROMAT,
  ITERABLE_APIKEY,
  MANAGER_VERSION,
  MSAL_CLIENT_ID,
  RUDDERSTACK_APIKEY,
  RUDDERSTACK_URL,
  WEBAPP_URL,
  WEBCONTENTS_URL,
};
